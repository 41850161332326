import React from "react"
import PropTypes from "prop-types"

import {useLazyQuery, useSubscription} from "@apollo/react-hooks"

import {Space, Tabs, Typography} from "antd"
import "antd/dist/antd.css"

import {GET_TEAM_QUESTION} from "../../graphql/queries"
import {QUESTION_ANSWERED_SUBSCRIPTION} from "../../graphql/subscriptions"

import {Leaderboard} from "../Teams"

import RoundModal from "./RoundModal"
import {RoundActions} from "./buttons"
import RoundTable from "./RoundTable"
import UpdateQuizModal from "./UpdateQuizModal"
import "./Manage.css"

const {TabPane} = Tabs
const {Title} = Typography


export function Manage(props) {
  const quiz = props.quiz
  const [getTeamQuestion] = useLazyQuery(GET_TEAM_QUESTION, {fetchPolicy: "network-only"})

  useSubscription(
    QUESTION_ANSWERED_SUBSCRIPTION,
    {
      variables: {quiz_id: quiz.id},
      onSubscriptionData(options) {
        getTeamQuestion({
          variables: {
            "team_id": options.subscriptionData.data.questionAnswered.team.id,
            "question_id": options.subscriptionData.data.questionAnswered.question.id,
          }
        })
      }
    }
  )

  const getNextRoundNumber = () => {
    const roundNumbers =  quiz.rounds.reduce( (acc, item) => {
      acc.push(item.number)
      return acc
    }, [])

    for (let i = 1; i <= roundNumbers.length + 1; i++) {
      if (roundNumbers.indexOf(i) === -1) {
        return i
      }
    }
  }

  const tabBarExtraContent = <RoundModal quiz={quiz} nextRoundNumber={getNextRoundNumber()} />
  const quizUrl = `${window.location.protocol}//${window.location.host}/quizzes/${quiz.code}/`

  return (
    <div className="site-layout-content">
      <Title style={{"marginBottom": "10px"}}>{quiz.name}</Title>
      <UpdateQuizModal quiz={quiz} />

      <p><a href={quizUrl}>{quizUrl}</a></p>
      <Tabs defaultActiveKey="1" tabBarExtraContent={tabBarExtraContent} style={{overflow: "visible"}}>
        <TabPane tab="Teams" key="1">
          <Leaderboard rounds={quiz.rounds} />
        </TabPane>

        {
          quiz.rounds.map((round, index) =>
            <TabPane tab={round.number + ". " + round.name} key={index + 2}>
              <Space style={{cursor: "pointer", "color": "#1890ff", marginBottom: "16px"}}>
                <RoundActions quiz={quiz} round={round} />
                <RoundModal quiz={quiz} round={round} />
              </Space>

              <RoundTable round={round} teams={props.teams} />
            </TabPane>
          )
        }
      </Tabs>
    </div>
  )
}


Manage.propTypes = {
  quiz: PropTypes.object,
  teams: PropTypes.arrayOf(PropTypes.object),
}


export default Manage
