import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import * as Sentry from "@sentry/react"
import {Integrations} from "@sentry/tracing"
import {BrowserRouter} from "react-router-dom"

import * as serviceWorker from "./serviceWorker"


// TODO: put this in an env variable (but I do not have access to netlify)
const SENTRY_DSN = "https://5f7bfc654af6449c9036787901848ac0@o176154.ingest.sentry.io/5526708"

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 0.1,
})


ReactDOM.render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
