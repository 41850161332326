import React from "react"
import PropTypes from "prop-types"

import {List} from "antd"

import {Question} from "../Questions"

import "./Round.scss"


export function Round({round, pointsLoading, answersLoading}) {
  const roundStarted = round.questions.some(q => q.released_date)

  const renderListItem = question => {
    return (
      <List.Item>
        <Question
          key={question.id}
          question={question}
          roundCompleted={round.completed_date !== null}
          pointsReleased={!pointsLoading && !answersLoading && round.points_released_date !== null}
        />
      </List.Item>
    )
  }

  return (
    <div className="round">
      {roundStarted && round.help_text ? (
        <p className="round__help-text">{round.help_text}</p>
      ) : null}
      <List
        dataSource={round.questions}
        renderItem={renderListItem}
      />
    </div>
  )
}


Round.propTypes = {
  round: PropTypes.shape({
    id: PropTypes.string,
    questions: PropTypes.arrayOf(PropTypes.object),
    help_text: PropTypes.string,
  }),
}

export default Round
