import React from "react"

import "./QuestionPoints.scss"


export function QuestionPoints(props) {
  const {awardedPoints, availablePoints} = props

  let pointsStatus

  if (awardedPoints >= availablePoints) {
    pointsStatus = "correct"
  } else if (awardedPoints > 0 && awardedPoints < availablePoints) {
    pointsStatus = "partially-correct"
  } else {
    pointsStatus = "incorrect"
  }

  return (
    <div className="question-points">
      <span className={`question-points__score question-points__score--${pointsStatus}`}>
        {awardedPoints || 0}
      </span>
    </div>
  )
}

export default QuestionPoints
