import React, {useState} from "react"

import {Auth} from "aws-amplify"

import {Button, Form, Input, Typography} from "antd"

const {Text} = Typography


export function LoginPage() {
  const [cognitoError, setCognitoError] = useState()
  const [form] = Form.useForm()

  const onFinish = values => {
    Auth.signIn(values["email"], values["password"]).then(user => {
      const params = new URLSearchParams(window.location.search)
      const redirectPath = params.get("redirect") || ""
      window.location.replace(redirectPath)
    }).catch(error => {
      setCognitoError(error)
    })
  }

  return (
    <div className="site-layout-content">
      <h1>Login</h1>

      <p>Please note that if you registered on the old Quizzit site, you will need to re-register.</p>

      {cognitoError ? (
        <p><Text type="danger">{cognitoError.message}</Text></p>
      ) : (
        null
      )}

      <Form
        form={form}
        id="register"
        name="register"
        onFinish={onFinish}
        requiredMark={false}
        layout="vertical"
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              type: "email",
              message: "Enter a valid email",
            },
            {
              required: true,
              message: "Please enter your email",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Enter a password",
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item style={{marginBottom: "0", marginTop: "16px"}}>
          <Button type="primary" htmlType="submit">
              Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default LoginPage
