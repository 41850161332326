import React, {useContext} from "react"

import {useQuery} from "@apollo/react-hooks"
import {GET_TEAMS_WITH_SCORES} from "../../graphql/queries"
import {UserContext} from "../../auth/user"

export const QuizContext = React.createContext()


export const getMyTeamInfo = (teams, userId) => {
  for (var team of teams) {
    for (var teamMember of team.team_members) {
      if (teamMember.user_id === userId) {
        return {myTeam: team, myTeamMember: teamMember}
      }
    }
  }
  return {}
}


export function QuizContextProvider(props) {
  const {loading, error, data} = useQuery(GET_TEAMS_WITH_SCORES, {variables: {"quiz_code": props.quiz.code}})
  const {user, userLoading, userError} = useContext(UserContext)

  let value = {
    quizId: props.quiz.id,
    quizCode: props.quiz.code,
    myTeamLoading: loading || userLoading,
    myTeamError: error || userError,
    myTeam: null,
    myTeamMember: null,
    userHasTeam: false,
    userCanEdit: false,
    getTeamQuestion: null,
    getMyTeamRound: null,
    getMyTeamQuestion: null,
    isAdmin: false,
  }

  if (data && user) {
    const isAdmin = props.quiz.created_by && props.quiz.created_by.id === user.id
    const {myTeam = null, myTeamMember = null} = getMyTeamInfo(data.getTeams, user.id)
    let teamRoundLookup = {}
    let teamQuestionLookup = {}

    if (isAdmin) {
      for (let team of data.getTeams) {
        for (let teamRound of team.team_rounds) {
          teamRoundLookup[teamRound.id] = teamRound
          for (let teamQuestion of teamRound.team_questions) {
            teamQuestionLookup[teamQuestion.id] = teamQuestion
          }
        }
      }
    } else if (myTeam) {
      for (let teamRound of myTeam.team_rounds) {
        teamRoundLookup[teamRound.id] = teamRound
        for (let teamQuestion of teamRound.team_questions) {
          teamQuestionLookup[teamQuestion.id] = teamQuestion
        }
      }
    }

    const getMyTeamRound = (round_id) => {
      if (myTeam) {
        const teamRoundId = `${myTeam.id}:${round_id}`
        return teamRoundLookup[teamRoundId]
      }
    }

    const getTeamQuestion = (team_id, question_id) => {
      const teamQuestionId = `${team_id}:${question_id}`
      return teamQuestionLookup[teamQuestionId]
    }

    const getMyTeamQuestion = (question_id) => {
      if (myTeam) {
        const teamQuestionId = `${myTeam.id}:${question_id}`
        return teamQuestionLookup[teamQuestionId]
      }
    }

    value["myTeam"] = myTeam
    value["myTeamMember"] = myTeamMember
    value["userHasTeam"] = myTeamMember && myTeamMember.confirmed
    value["userCanEdit"] = myTeamMember && myTeamMember.can_edit
    value["getMyTeamQuestion"] = getMyTeamQuestion
    value["getTeamQuestion"] = getTeamQuestion
    value["getMyTeamRound"] = getMyTeamRound
    value["isAdmin"] = isAdmin
  }

  return (
    <QuizContext.Provider value={value}>
      {props.children}
    </QuizContext.Provider>
  )
}

export default QuizContextProvider
