import {gql} from "apollo-boost"


export const QUESTION_ANSWERED_SUBSCRIPTION = gql`
  subscription questionAnswered {
    questionAnswered {
      id
      team {
        id
      }
      question {
        id
      }
      answer
    }
  }
`


export const QUESTION_RELEASED_SUBSCRIPTION = gql`
  subscription questionReleased {
    questionReleased {
      id
      released_date
    }
  }
`

export const QUESTION_UNRELEASED_SUBSCRIPTION = gql`
  subscription questionUnreleased {
    questionUnreleased {
      id
      released_date
    }
  }
`

export const ROUND_RELEASED_SUBSCRIPTION = gql`
  subscription roundQuestionsReleased {
    roundQuestionsReleased {
      id
      released_date
    }
  }
`

export const ROUND_UNRELEASED_SUBSCRIPTION = gql`
  subscription roundQuestionsUnreleased {
    roundQuestionsUnreleased {
      id
      released_date
    }
  }
`

export const ROUND_ENDED_SUBSCRIPTION = gql`
  subscription roundEnded {
    roundEnded {
      id
      completed_date
    }
  }
`

export const ROUND_REOPENED_SUBSCRIPTION = gql`
  subscription roundReopened {
    roundReopened {
      id
      completed_date
    }
  }
`

export const POINTS_RELEASED_SUBSCRIPTION = gql`
  subscription roundPointsReleased {
    roundPointsReleased {
      id
      points_released_date
      questions {
        id
        number
        question
        picture
        released_date
        team_question {
          id
          answer
          points
        }
      }
    }
  }
`

export const ASKED_TO_JOIN_TEAM_SUBSCRIPTION = gql`
  subscription askedToJoinTeam {
    askedToJoinTeam {
      id
      name
      confirmed
    }
  }
`

export const TEAM_CREATED_SUBSCRIPTION = gql`
  subscription teamCreated {
    teamCreated {
      id
    }
  }
`

export const TEAM_MEMBER_CONFIRMED_SUBSCRIPTION = gql`
  subscription teamMemberConfirmed {
    teamMemberConfirmed {
      id
    }
  }
`

export const TEAM_MEMBER_REJECTED_SUBSCRIPTION = gql`
  subscription teamMemberRejected {
    teamMemberRejected {
      id
    }
  }
`
