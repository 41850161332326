import React, {useState} from "react"

import {Auth} from "aws-amplify"

import {Button, Form, Input, Typography} from "antd"

const {Text} = Typography


export function RegisterPage() {
  const [cognitoError, setCognitoError] = useState()
  const [form] = Form.useForm()

  const onFinish = values => {
    Auth.signUp({
      username: values["email"],
      password: values["password"],
      attributes: {
        email: values["email"],
        name: values["first_name"] + " " + values["last_name"],
        given_name: values["first_name"],
        family_name: values["last_name"],
      }
    }).then(() => {
      Auth.signIn(values["email"], values["password"]).then(user => {
        window.location = "/"
      }).catch(err => {
        console.log(err)
      })
    }).catch(error => {
      setCognitoError(error)
    })
  }

  return (
    <div className="site-layout-content">
      <h1>Register</h1>
      {cognitoError ? (
        <p><Text type="danger">{cognitoError.message}</Text></p>
      ) : (
        null
      )}

      <Form
        form={form}
        id="register"
        name="register"
        onFinish={onFinish}
        initialValues={{
          first_name: localStorage.getItem("name"),
        }}
        requiredMark={false}
        layout="vertical"
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              type: "email",
              message: "Enter a valid email",
            },
            {
              required: true,
              message: "Please enter your email",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="First name"
          name="first_name"
          rules={[{required: true, message: "Please enter your first name"}]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Last name"
          name="last_name"
          rules={[{required: true, message: "Please enter your last name"}]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Enter a password",
            },
            () => ({
              validator(rule, value) {
                if (value.length > 0 && value.length < 8) {
                  return Promise.reject("Password must be at least 8 characters")
                }
                if (value.length > 0 && !/\d/.test(value)) {
                  return Promise.reject("Password must have numbers")
                }

                return Promise.resolve()
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item style={{marginBottom: "0", marginTop: "16px"}}>
          <Button type="primary" htmlType="submit">
            Register
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default RegisterPage
