import React from "react"
import PropTypes from "prop-types"

import {useQuery} from "@apollo/react-hooks"

import {GET_QUIZ, GET_TEAMS_WITH_SCORES} from "../graphql/queries"
import {Quiz, QuizContextProvider} from "../components/Quizzes"

import "./QuizPage.scss"


export function QuizPage(props) {
  const quizCode = props.match.params.quizCode
  const {loading, error, data} = useQuery(GET_QUIZ, {variables: {"code": quizCode}})

  // fetch the teams now, so that subsequent calls will already be cached
  useQuery(GET_TEAMS_WITH_SCORES, {variables: {"quiz_code": quizCode}})

  if (loading) return <div className="site-layout-content quiz-page"><p>Loading...</p></div>
  if (error) return <div className="site-layout-content quiz-page"><p>Error :(</p></div>

  return (
    <div className="site-layout-content quiz-page">
      <QuizContextProvider quiz={data.getQuiz}>
        <Quiz quiz={data.getQuiz} />
      </QuizContextProvider>
    </div>
  )
}

QuizPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      quizCode: PropTypes.string
    })
  })
}

export default QuizPage
