import React from "react"
import "./App.scss"

import {
  HomePage,
  LoginPage,
  RegisterPage,
  QuizManagePage,
  QuizPage,
} from "./pages"

import {ApolloClient} from "apollo-client"
import {ApolloProvider} from "@apollo/react-hooks"
import {InMemoryCache} from "apollo-cache-inmemory"

import * as Sentry from "@sentry/react"

import {Route, Switch} from "react-router-dom"
import {Layout} from "antd"

import {Navigation} from "./components/Navigation"

import link from "./auth/link"
import {UserContextProvider} from "./auth/user"


const {Content, Footer} = Layout


function App() {
  const cache = new InMemoryCache({
    dataIdFromObject: object => object.id,
  })

  const client = new ApolloClient({
    link: link,
    cache: cache,
  })

  return (
    <ApolloProvider client={client}>
      <UserContextProvider>
        <Layout className="layout">
          <Navigation />
          <Content className="content">
            <div className="App">
              <Switch>
                <Route path="/quizzes/:quizCode/manage" component={QuizManagePage} />
                <Route path="/quizzes/:quizCode/" component={QuizPage} />
                <Route path="/register/" component={RegisterPage} />
                <Route path="/login/" component={LoginPage} />
                <Route
                  path="/"
                  render={(props) => (
                    <HomePage loggedIn={"abb" !== undefined} />
                  )}
                 />
              </Switch>
            </div>
          </Content>
          <Footer className="footer"></Footer>
        </Layout>
      </UserContextProvider>
    </ApolloProvider>
  )
}

export default Sentry.withProfiler(App)
