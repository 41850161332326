import React, {useState, useEffect} from "react"

import {useMutation} from "@apollo/react-hooks"

import {MARK_TEAM_QUESTION} from "../../graphql/mutations"
import {InputNumber} from "antd"


export function PointsInput(props) {
  const [value, setValue] = useState(props.team_question.points)
  const [markTeamQuestion] = useMutation(MARK_TEAM_QUESTION)
  var [teamId, questionId] = props.team_question.id.split(':')

  useEffect(() => {
    setValue(props.team_question.points)
  }, [props.team_question.points])

  return (
    <InputNumber
      className={"manage-question__points " + (value == null ? "manage-question__points--unmarked" : "manage-question__points--marked")}
      value={value}
      width="2"
      onBlur={event => {
        let points = event.target.value
        if (points === "") points = null
        // TODO check float
        if (!isNaN(points)) {
          markTeamQuestion({variables: {team_id: teamId, question_id: questionId, points: points}})
          setValue(event.target.value)
        }
      }}
      style={{textAlign: "center"}}
      inputStyle="text-align: right"
    />
  )
}
