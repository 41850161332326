import React, {useContext} from "react"
import {Layout, Menu} from "antd"
import {Auth} from "aws-amplify"

import {UserContext} from "../../auth/user"

const {Header} = Layout


export function Navigation(props) {
  const {user, userLoading} = useContext(UserContext)

  async function signOut() {
    try {
      await Auth.signOut()
      window.location.reload(false)
    } catch (error) {
      console.log("Error signing out: ", error)
    }
  }

  return (
    <Header>
      <div className="logo" />

      <Menu theme="dark" mode="horizontal">
        <Menu.Item key="1"><a href="/">Quizzes</a></Menu.Item>

        {!userLoading ? (
          (user == null || user.is_anonymous) ? ([
            <Menu.Item key="2">
              <a href={`/login?redirect=${window.location.pathname}`}>Login</a>
            </Menu.Item>,
            <Menu.Item key="3"><a href="/register">Register</a></Menu.Item>
          ]) : ([
            <Menu.Item key="2">{user.name}</Menu.Item>,
            <Menu.Item key="3" onClick={() => {signOut()}}>Logout</Menu.Item>
          ])
        ) : null}

      </Menu>
    </Header>
  )
}

export default Navigation
