import React, {useContext, useRef, useState} from "react"
import PropTypes from "prop-types"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleDoubleRight
} from "@fortawesome/free-solid-svg-icons"

import {useClickOff} from "../../hooks"
import {QuizContext} from "../Quizzes"
import {getRoundPoints} from "./utils"

import "./RoundSelector.scss"


function RoundSummary({round}) {
  const {getMyTeamRound, userHasTeam} = useContext(QuizContext)
  const showPoints = (
    userHasTeam &&
    round.completed_date &&
    round.points_released_date
  )

  return (
    <>
      <span className="round-selector__name">
        <span className="round-selector__number">{round.number}.</span> {round.name}
      </span>
      {showPoints &&
        <span className="round-selector__points">{getRoundPoints(getMyTeamRound(round.id))}</span>
      }
    </>
  )
}

RoundSummary.propTypes = {
  round: PropTypes.object,
}


function RoundSelectorOption(props) {
  const {isActive, handleSelect, round} = props

  return (
    <div
      className={`
        round-selector__option
        ${isActive ? "round-selector__option--active" : ""}
      `}
      onClick={() => handleSelect()}
    >
      <RoundSummary round={round} />
    </div>
  )
}

RoundSelectorOption.propTypes = {
  isActive: PropTypes.bool,
  handleSelect: PropTypes.func,
  round: PropTypes.object,
}


function RoundSelectorOptionList(props) {
  const {rounds, activeRound, handleSelectRound} = props

  return (
    <div className="round-selector__option-list">
      {
        rounds.map((round, index) =>
          <RoundSelectorOption
            key={index}
            round={round}
            handleSelect={() => handleSelectRound(index)}
            isActive={round===activeRound}
          />
        )
      }
    </div>
  )
}

RoundSelectorOptionList.propTypes = {
  handleSelectRound: PropTypes.func,
  rounds: PropTypes.arrayOf(PropTypes.object),
  activeRound: PropTypes.object,
}


function NavigateRoundControl(props) {
  const {
    activeRoundIndex,
    selectRound,
    totalRounds,
    direction,
    icon
  } = props

  let destinationIndex
  switch (direction) {
    case "first":
      destinationIndex = 0
      break
    case "previous":
      destinationIndex = activeRoundIndex - 1
      break
    case "next":
      destinationIndex = activeRoundIndex + 1
      break
    case "last":
      destinationIndex = totalRounds - 1
      break
    default:
      destinationIndex = 0
  }

  if (
    destinationIndex === activeRoundIndex ||
    destinationIndex < 0 ||
    destinationIndex > totalRounds - 1
  ) {
    return (
      <button
        className={
          `round-selector__control round-selector__control--disabled ` +
          `round-selector__control--${direction}`
        }
        disabled
      >
        <FontAwesomeIcon icon={icon} />
      </button>
    )
  }

  return (
    <button
      className={`round-selector__control round-selector__control--${direction}`}
      onClick={() => selectRound(destinationIndex)}
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  )
}

NavigateRoundControl.propTypes = {
  activeRoundIndex: PropTypes.number,
  selectRound: PropTypes.func,
  totalRounds: PropTypes.number,
  direction: PropTypes.oneOf(["first", "previous", "next", "last"]),
  icon: PropTypes.object
}


export function RoundSelector(props) {
  const {rounds, activeRoundIndex, handleSelectRound} = props
  let [isOpen, setOpen] = useState(false)
  const clickOffRef = useRef(null)

  useClickOff(clickOffRef, () => setOpen(false))

  const activeRound = rounds[activeRoundIndex]

  return (
    <div className="round-selector" ref={clickOffRef}>
      <NavigateRoundControl
        activeRoundIndex={activeRoundIndex}
        selectRound={handleSelectRound}
        totalRounds={rounds.length}
        direction="first"
        icon={faAngleDoubleLeft}
      />
      <NavigateRoundControl
        activeRoundIndex={activeRoundIndex}
        selectRound={handleSelectRound}
        totalRounds={rounds.length}
        direction="previous"
        icon={faAngleLeft}
      />

      <div className="round-selector__content">
        {isOpen ?
          <RoundSelectorOptionList
            rounds={rounds}
            activeRound={activeRound}
            handleSelectRound={(index) => {setOpen(false); handleSelectRound(index)}}
          /> :
          <div className="round-selector__title" onClick={() => setOpen(true)}>
            <RoundSummary round={activeRound} />
          </div>
        }
      </div>

      <NavigateRoundControl
        activeRoundIndex={activeRoundIndex}
        selectRound={handleSelectRound}
        totalRounds={rounds.length}
        direction="next"
        icon={faAngleRight}
      />
      <NavigateRoundControl
        activeRoundIndex={activeRoundIndex}
        selectRound={handleSelectRound}
        totalRounds={rounds.length}
        direction="last"
        icon={faAngleDoubleRight}
      />
    </div>
  )
}

RoundSelector.propTypes = {
  rounds: PropTypes.arrayOf(PropTypes.object),
  activeRoundIndex: PropTypes.number,
  handleSelectRound: PropTypes.func
}

export default RoundSelector
