import React, {useContext} from "react"

import {QuizContext} from "../Quizzes"
import {TeamMemberList, CreateTeamModal} from "../Teams"


export function TeamControls(props) {
  const {myTeamLoading, myTeamError, myTeam, myTeamMember} = useContext(QuizContext)

  if (myTeamLoading) return <div className="site-layout-content quiz-page"><p>Loading...</p></div>
  if (myTeamError) return <div className="site-layout-content quiz-page"><p>Error :(</p></div>

  if (myTeam) {
    return (
      <>
        <h2 className="quiz__team-heading">{myTeam.name}</h2>
        <TeamMemberList team={myTeam} canEditTeam={myTeamMember.can_edit} />
      </>
    )
  } else {
    return <CreateTeamModal />
  }
}


export default TeamControls
