import React, {useContext, useState} from "react"

import {useMutation} from "@apollo/react-hooks"

import {Space} from "antd"
import {EditOutlined} from "@ant-design/icons"

import {DELETE_QUESTION, UPDATE_QUESTION} from "../../graphql/mutations"
import {GET_QUIZ} from "../../graphql/queries"

import {ReleaseButton, UnreleaseButton} from "./buttons"
import QuestionForm from "./QuestionForm"
import {QuizContext} from "../Quizzes"


function ReadOnlyQuestion(props) {
  return (
    <div>
      <div className="manage-question__question-container">
        <Space className="manage-question__question">
          <span>{props.question.number}. {props.question.question}</span>
          <EditOutlined onClick={props.handleEditClick} />
        </Space>

        {props.question.picture ? (
          <img className="manage-question__image" src={process.env.REACT_APP_MEDIA_URL + props.question.picture} alt="" />
        ) : null}

        {props.question.audio ? (
          <div className="question__audio">
            <audio controls controlsList="nodownload">
              <source src={process.env.REACT_APP_MEDIA_URL + props.question.audio} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        ) : null}

        <p><strong>{props.question.answer}</strong></p>
      </div>
      {props.question.released_date ? (
        <UnreleaseButton question_id={props.question.id} quiz_id={props.quiz_id} />
      ) : (
        <ReleaseButton question_id={props.question.id} quiz_id={props.quiz_id} />
      )}
    </div>
  )
}


function Question(props) {
  const {quizCode, quizId} = useContext(QuizContext)
  const [editMode, setEditMode] = useState(false)
  const [updateQuestion, {loading: updateLoading, error: updateError}]  = useMutation(UPDATE_QUESTION, {
    onCompleted() {
      setEditMode(false)
    },
  })
  const [deleteQuestion] = useMutation(DELETE_QUESTION, {
    refetchQueries: [{query: GET_QUIZ, variables: {"code": quizCode}}],
  })

  const handleUpdateQuestionSubmit = (questionId, values) => {
    updateQuestion({
      variables: {
        id: questionId,
        number: values.number,
        question: values.question,
        picture: values.picture,
        audio: values.audio,
        answer: values.answer,
        points: values.points,
      }
    })
  }

  return (
    <div>
      {editMode ? (
        <QuestionForm
          question={props.question}
          handleSubmit={values => {
            handleUpdateQuestionSubmit(props.question.id, values)
          }}
          handleCancelClick={event => {
            setEditMode(false)
          }}
          handleDeleteClick={() => {
            deleteQuestion({variables: {id: props.question.id}})
            setEditMode(false)
          }}
          saving={updateLoading}
          error={updateError}
        />
      ) : (
        <ReadOnlyQuestion
          question={props.question}
          quiz_id={quizId}
          handleEditClick={event => {
            setEditMode(true)
          }}
        />
      )}
    </div>
  )
}

export default Question
