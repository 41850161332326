import React, {useContext} from "react"
import PropTypes from "prop-types"

import {useLazyQuery, useQuery, useSubscription} from "@apollo/react-hooks"
// import {useMediaQuery} from "react-responsive"

import {GET_TEAMS_WITH_SCORES} from "../../graphql/queries"
import {TEAM_CREATED_SUBSCRIPTION} from "../../graphql/subscriptions"

import {WideLeaderboard} from "./WideLeaderboard"
import {QuizContext} from "../Quizzes"

import "./Leaderboard.scss"


const formatTeamsAsDataSource = (teams) => {
  /**
  * Formats teams as received by graphql for antd table data source
  */
  return teams.map((team, index) => {
    let data = {
      key: team.id,
      team: team,
    }
    let totalPoints = 0
    team.team_rounds.forEach((teamRound) => {
      if (teamRound.round.points_released_date) {
        let roundPoints = teamRound.team_questions.reduce((acc, item) => acc + item.points, 0)
        data[teamRound["round"]["id"]] = roundPoints
        totalPoints += roundPoints
      }
    })
    data["total"] = totalPoints
    return data
  })
}


export function Leaderboard({rounds}) {
  const {quizCode, quizId} = useContext(QuizContext)
  const {loading, error, data} = useQuery(GET_TEAMS_WITH_SCORES, {variables: {"quiz_code": quizCode}})
  const [getTeamsWithScores] = useLazyQuery(GET_TEAMS_WITH_SCORES, {fetchPolicy: "network-only"})

  useSubscription(TEAM_CREATED_SUBSCRIPTION, {
    variables: {quiz_id: quizId},
    onSubscriptionData() {
      getTeamsWithScores({variables: {quiz_code: quizCode}})
    }
  })

  // const screenIsMedium = useMediaQuery({
  //   query: "screen and (min-width: 960px)"
  // })

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error :(</p>

  const leaderboardDataSource = formatTeamsAsDataSource(data.getTeams)

  return (
    <WideLeaderboard
      dataSource={leaderboardDataSource}
      rounds={rounds}
    />
  )
  // if (screenIsMedium) {
  // } else {
  //   return <div>Mobile Leaderboard...</div>
  // }
}

Leaderboard.propTypes = {
  rounds: PropTypes.arrayOf(PropTypes.object),
}

export default Leaderboard
