import React, {useState} from "react"

import {ASK_TO_JOIN_TEAM} from "../../graphql/mutations"
import {GET_TEAM} from "../../graphql/queries"

import {useMutation} from "@apollo/react-hooks"
import {Button, Form, Input, Modal} from "antd"


export function AskToJoinTeamModal(props) {
  const [visible, setVisible] = useState(0)
  const [confirmLoading, setConfirmLoading] = useState(0)
  const [askToJoinTeamMutation] = useMutation(ASK_TO_JOIN_TEAM, {
    onCompleted({askToJoinTeam: {id, name, confirmed}}) {
      setVisible(false)
      setConfirmLoading(false)
    },
    refetchQueries: [
      {query: GET_TEAM, variables: {"id": props.team.id}},
    ],
  })

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        setConfirmLoading(true)
        localStorage.setItem("name", values.name)
        askToJoinTeamMutation({
          variables: {
            team_id: props.team.id,
            name: values.name,
          }
        })
        form.resetFields()
      })
      .catch(info => {
        console.log("Validate Failed:", info)
      })
  }

  const [form] = Form.useForm()

  return (
    <div>
      <Button size="small" onClick={() => setVisible(true)} style={{marginBottom: "16px", marginTop: "8px"}}>
        Ask to join
      </Button>
      <Modal
        title={"Ask to join " + props.team.name}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        okText="Ask to join"
        cancelText="Cancel"
      >
        <Form
          form={form}
          id="ask-to-join-team"
          name="ask-to-join-team"
          initialValues={{name: localStorage.getItem("name")}}
          requiredMark={false}
          layout="vertical"
        >
          <Form.Item
            label="Your name"
            name="name"
            rules={[{required: true, message: "Please enter your name"}]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default AskToJoinTeamModal
