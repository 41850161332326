import React, {useContext} from "react"
import PropTypes from "prop-types"

import AskToJoinTeamModal from "./AskToJoinTeamModal"
import {Table, Tooltip} from "antd"
import {QuizContext} from "../Quizzes"


function TeamHeading(props) {
  const {team} = props
  const {quizCode, myTeam, myTeamMember, isAdmin} = useContext(QuizContext)

  const teamIsMyTeam = myTeam && (myTeam.id === team.id)

  return (
    <div className="team-heading">
      {teamIsMyTeam ? (
        <strong>{team.name}</strong>
      ) : (
        team.name
      )}

      <span className="team-captain">{team.captain_name} (c)</span>

      {teamIsMyTeam && !myTeamMember.confirmed && (
        <span className="request-pending">Request pending</span>
      )}

      {!myTeam && !isAdmin && (
        <AskToJoinTeamModal team={team} quizCode={quizCode} />
      )}
    </div>
  )
}


export function WideLeaderboard(props) {
  const {
    showUnreleasedPoints,
    rounds,
    dataSource,
  } = props

  const renderTeamHeading = (team) => (
    <TeamHeading team={team} />
  )

  let columns = [
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
      render: renderTeamHeading,
      fixed: "left",
      width: "140px",
    },
  ]

  columns = columns.concat(rounds.map((round) => {
    const renderColumn = points => {
      return (
        <span>
          {(!showUnreleasedPoints && round.points_released_date == null) ? (
            "-"
          ) : (
            points
          )}
        </span>
      )
    }

    return {
      title: (
        <span className="leaderboard__round-heading">
          <Tooltip
            title={round.name}
            placement="bottom"
            trigger={["hover", "focus", "click"]}
          >
            {rounds.length > 9 ? "RD" : "RND"} {round.number}{" "}
          </Tooltip>
        </span>
      ),
      dataIndex: round.id,
      key: round.id,
      align: "center",
      render: renderColumn,
      sorter: (a, b) => a[round.id] - b[round.id],
      sortDirections: ["ascend", "descend"],
      width: "65px",
      showSorterTooltip: false,
    }
  }))

  columns = columns.concat({
    title: (
      <span className="leaderboard__round-heading">
        <Tooltip
          title="Total Score"
          placement="bottom"
          trigger={["hover", "focus", "click"]}
        >
          TOT
        </Tooltip>
      </span>
    ),
    dataIndex: "total",
    key: "total",
    align: "center",
    width: "60px",
    sorter: (a, b) => a.total - b.total,
    sortDirections: ["ascend", "descend"],
    defaultSortOrder: "descend",
    fixed: "right",
    showSorterTooltip: false,
  })

  return (
    <div className="leaderboard">
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        locale={{emptyText: "No teams"}}
        scroll={{x: 540}}
      />
    </div>
  )
}

WideLeaderboard.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object),
  rounds: PropTypes.arrayOf(PropTypes.object),
  showUnreleasedPoints: PropTypes.bool,
}

export default WideLeaderboard
