import {Form} from "antd"
import styled from "styled-components"


export const CompactForm = styled(Form)`
  .ant-form-item:not(:nth-last-child(2)) {
    margin-bottom: 10px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`
