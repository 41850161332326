import React from "react"
import PropTypes from "prop-types"

import {useQuery} from "@apollo/react-hooks"

import {GET_QUIZ, GET_TEAMS_WITH_SCORES} from "../graphql/queries"

import {Manage} from "../components/QuizManagement"
import {QuizContextProvider} from "../components/Quizzes"

import "./QuizPage.scss"


export function QuizManagePage(props) {
  const quizCode = props.match.params.quizCode
  const {loading: quizLoading, error: quizError, data: quizData} = useQuery(GET_QUIZ, {variables: {"code": quizCode}})
  const {loading: teamsLoading, error: teamsError, data: teamsData} = useQuery(GET_TEAMS_WITH_SCORES, {variables: {"quiz_code": quizCode}})

  if (quizLoading || teamsLoading) return <div className="site-layout-content quiz-manage-page"><p>Loading...</p></div>
  if (quizError || teamsError) return <div className="site-layout-content quiz-manage-page"><p>Error</p></div>

  return (
    <div className="site-layout-content quiz-manage-page">
      <QuizContextProvider quiz={quizData.getQuiz}>
        <Manage quiz={quizData.getQuiz} teams={teamsData.getTeams} />
      </QuizContextProvider>
    </div>
  )
}

QuizManagePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      quizCode: PropTypes.string
    })
  })
}

export default QuizManagePage
