import React, {useState} from "react"
import {useApolloClient} from "@apollo/react-hooks"
import {Button, Form, message, Space, Upload} from "antd"
import {DeleteOutlined, LoadingOutlined, UploadOutlined} from "@ant-design/icons"

import {GET_PRESIGNED_URL} from "../../graphql/queries"

import "./AudioUploader.css"


function beforeUpload(file) {
  console.log(file.type)
  const isMP3 = file.type === "audio/mpeg"
  if (!isMP3) {
    message.error("You can only upload mp3 files!")
  }
  const isSmall = file.size / 1024 / 1024 < 1
  if (!isSmall) {
    message.error("Image must smaller than 1MB!")
  }
  return isMP3 && isSmall
}


export function AudioUploader(props) {
  const client = useApolloClient()
  const [hasFile, setHasFile] = useState(false)
  const fileUrl = props.filePath ? process.env.REACT_APP_MEDIA_URL + props.filePath : null
  const setFilePath = props.setFilePath

  const getPresignedUrl = async (filename) => {
    return await client.query({
      query: GET_PRESIGNED_URL,
      variables: {"path": props.upload_path, "filename": filename},
      fetchPolicy: "no-cache"
    })
  }

  const customRequest = async ({file, onSuccess}) => {
    getPresignedUrl(file.name).then(async (data) => {
      const presignedData = data.data.getPresignedUrl
      const fields = JSON.parse(presignedData.fields)
      const formData = new FormData()

      Object.entries(fields).forEach(([k, v]) => {
        formData.append(k, v)
      })

      formData.append("file", file)

      await fetch(presignedData.url, {
        method: "POST",
        body: formData,
      }).then(response => {
        const path = fields.key.replace(/^(media\/)/,"")
        setFilePath(path)
      })
    })

    setTimeout(() => {
      onSuccess("ok")
    }, 0)
  }

  const normFile = e => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const handleChange = (info) => {
    console.log(info)
    if (info.file.status === "uploading" || info.file.status === "done") {
      setHasFile(true)
    } else {
      setHasFile(false)
    }
  }

  const loading = hasFile && !fileUrl

  return (
    <Form.Item
      label={props.label}
      valuePropName="fileList"
      getValueFromEvent={normFile}
    >

      <Upload
        name={props.name}
        className="audio-uploader"
        listType="text"
        showUploadList={false}
        customRequest={customRequest}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        multiple={false}
        style={{marginBottom: 0, display: "block"}}
      >
        {fileUrl ? (
          <audio controls controlsList="nodownload">
            <source src={fileUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        ) : (
          <div>
            {loading ? (
              <LoadingOutlined />
            ): (
              <Button icon={<UploadOutlined />}>Upload</Button>
            )}
          </div>
        )}
      </Upload>

      {fileUrl ? (
        <Space size={5} align="center" onClick={() => setFilePath(null)} style={{cursor: "pointer", "color": "#1890ff"}}>
          <DeleteOutlined style={{display: "block", fontSize: "16px"}} />
          Clear
        </Space>
      ) : null}
    </Form.Item>
  )
}


export default AudioUploader
