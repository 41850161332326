import {useEffect} from "react"


export function useClickOff(ref, callback) {
  /**
   * Run callback when document is clicked outside of the passed ref
   */
  useEffect(
    () => {
      const handleClickOff = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
          callback()
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOff)

      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOff)
      }
    },
    [ref, callback]
  )
}


export default useClickOff
