import React, {useContext} from "react"

import {QuizList} from "../components/Quizzes"
import {CreateQuizModal} from "../components/QuizManagement"

import {UserContext} from "../auth/user"

import {Button} from "antd"
import {Form, Input} from "antd"


export function HomePage(props) {
  const {user} = useContext(UserContext)
  const [form] = Form.useForm()

  const onFinish = values => {
    const redirectPath = "/quizzes/" + values["code"].trim() + "/"
    window.location = redirectPath
  }

  const createQuizButton = <CreateQuizModal/>

  return (
    <div className="site-layout-content">

      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        id="find_quiz"
        name="find_quiz"
        onFinish={onFinish}
      >
        <Form.Item
          name="code"
          label="Quiz code"
          rules={[
            {
              required: true,
              message: "Please enter a quiz code",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">Go</Button>
        </Form.Item>
      </Form>

      {user && !user.is_anonymous ? (
        <QuizList title="My quizzes" userQuizzes={true} extra={createQuizButton}/>
      ) : (
        null
      )}

      <QuizList title="Past quizzes"/>
    </div>
  )
}

export default HomePage
