import React, {useContext, useState} from "react"

import {CREATE_TEAM} from "../../graphql/mutations"
import {GET_TEAMS_WITH_SCORES} from "../../graphql/queries"

import {useMutation} from "@apollo/react-hooks"
import {Button, Form, Input, Modal} from "antd"

import {QuizContext} from "../Quizzes"


export function CreateTeamModal(props) {
  const [visible, setVisible] = useState(0)
  const [confirmLoading, setConfirmLoading] = useState(0)
  const {quizId, quizCode} = useContext(QuizContext)
  const [createTeamMutation] = useMutation(CREATE_TEAM, {
    onCompleted({createTeam: {id, name, captain_name}}) {
      setVisible(false)
      setConfirmLoading(false)
    },
    refetchQueries: [
      {query: GET_TEAMS_WITH_SCORES, variables: {"quiz_code": quizCode}},
    ],
  })

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        setConfirmLoading(true)
        localStorage.setItem("name", values.captain_name)
        localStorage.setItem("team_name", values.name)
        createTeamMutation({
          variables: {
            name: values.name,
            captain_name: values.captain_name,
            quiz_id: quizId,
          }
        })
        form.resetFields()
      })
      .catch(info => {
        console.log("Validate Failed:", info)
      })
  }

  const [form] = Form.useForm()

  return (
    <div>
      <Button type="primary" onClick={() => setVisible(true)} style={{marginBottom: "16px"}}>
        Create team
      </Button>
      <Modal
        title="Create team"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        okText="Create team"
        cancelText="Cancel"
      >
        <Form
          form={form}
          id="create-team"
          name="create-team"
          initialValues={{name: localStorage.getItem("team_name"), captain_name: localStorage.getItem("name")}}
          requiredMark={false}
          layout="vertical"
        >
          <Form.Item
            label="Team name"
            name="name"
            rules={[{required: true, message: "Please enter your team name"}]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Your name"
            name="captain_name"
            rules={[{required: true, message: "Please enter your name"}]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default CreateTeamModal
