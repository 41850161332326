import React, {useState} from "react"
import {useApolloClient} from "@apollo/react-hooks"

import {Button, Form, Input, InputNumber, message, Space, Upload} from "antd"
import {DeleteOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons"

import AudioUploader from "../Upload/AudioUploader"
import {CompactForm} from "../forms"
import {GET_PRESIGNED_URL} from "../../graphql/queries"


function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!")
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!")
  }
  return isJpgOrPng && isLt2M
}


function QuestionForm(props) {
  const client = useApolloClient()
  const [loading, setLoading] = useState(false)
  let picture = props.question ? props.question.picture : null
  const [imagePath, setImagePath] = useState(picture)
  let audio = props.question ? props.question.audio : null
  const [audioPath, setAudioPath] = useState(audio)
  const [form] = Form.useForm()

  const imageUrl = imagePath ? process.env.REACT_APP_MEDIA_URL + imagePath : null

  let initialValues = {"number": props.nextQuestionNumber, "points": 1}
  if (props.question) {
    initialValues = {
      "number": props.question.number,
      "question": props.question.question,
      "answer": props.question.answer,
      "points": props.question.points,
    }
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{marginTop: 8}}>Upload</div>
    </div>
  )

  const getPresignedUrl = async (filename) => {
    return await client.query({
      query: GET_PRESIGNED_URL,
      variables: {"path": "pictures", "filename": filename},
      fetchPolicy: "no-cache"
    })
  }

  const customRequest = async ({file, onSuccess}) => {
    getPresignedUrl(file.name).then(async (data) => {
      const presignedData = data.data.getPresignedUrl
      console.log("Uploading...", presignedData)
      const fields = JSON.parse(presignedData.fields)
      const formData = new FormData()

      Object.entries(fields).forEach(([k, v]) => {
        formData.append(k, v)
      })
      formData.append("file", file)

      await fetch(presignedData.url, {
        method: "POST",
        body: formData,
      }).then(response => {
        const path = fields.key.replace(/^(media\/)/,"")
        setImagePath(path)
      })
    })

    setTimeout(() => {
      onSuccess("ok")
    }, 0)
  }

  const normFile = e => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const handlePictureChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true)
    } else if (info.file.status === "done") {
      setLoading(false)
    }
  }

  return (
    <CompactForm
      form={form}
      id="edit-question"
      name="edit-question"
      initialValues={initialValues}
      onFinish={(values) => {
        values["picture"] = imagePath
        values["audio"] = audioPath
        return props.handleSubmit(values)
      }}
      autoComplete="off"
      requiredMark={false}
      layout="vertical"
      style
    >
      <fieldset disabled={props.saving}>
        <Form.Item
          label="Number"
          name="number"
          rules={[{required: true, message: "Please enter a number"}]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Question"
          name="question"
          rules={[{required: true, message: "Please enter a question"}]}
        >
          <Input.TextArea style={{maxWidth: 400}} />
        </Form.Item>

        <Form.Item
          label="Picture"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            name="picture"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            customRequest={customRequest}
            beforeUpload={beforeUpload}
            onChange={handlePictureChange}
            multiple={false}
            style={{marginBottom: 0}}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="" style={{width: "100%"}} />
            ) : uploadButton}
          </Upload>

          {imageUrl ? (
            <Space size={5} align="center" onClick={() => setImagePath(null)} style={{cursor: "pointer", "color": "#1890ff"}}>
              <DeleteOutlined style={{display: "block", marginRight: "5px", fontSize: "16px"}} />
                Clear
            </Space>
          ) : null}
        </Form.Item>

        <AudioUploader
          label="Audio"
          name="audio"
          upload_path="audio"
          filePath={audioPath}
          setFilePath={setAudioPath}
        />

        <Form.Item
          label="Answer"
          name="answer"
          rules={[{required: true, message: "Please enter an answer"}]}
        >
          <Input style={{maxWidth: 400}} />
        </Form.Item>
        <Form.Item
          label="Points"
          name="points"
          rules={[{required: true, message: "Please enter the points"}]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" disabled={props.saving}>{props.saving ? "Saving..." : "Save"}</Button>
            <Button type="secondary" htmlType="submit" onClick={props.handleCancelClick} disabled={props.saving}>Cancel</Button>
            {props.handleDeleteClick ? (
              <Button type="primary" htmlType="submit" onClick={props.handleDeleteClick} danger disabled={props.saving}>Delete</Button>
            ) : null}
          </Space>
        </Form.Item>
      </fieldset>
    </CompactForm>
  )
}

export default QuestionForm
