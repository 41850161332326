import React, {useState} from "react"

import {useMutation} from "@apollo/react-hooks"

import {Button, Form, Input, InputNumber, Modal, Space} from "antd"
import {EditOutlined, PlusCircleOutlined} from "@ant-design/icons"

import {GET_QUIZ} from "../../graphql/queries"
import {CREATE_ROUND, UPDATE_ROUND, DELETE_ROUND} from "../../graphql/mutations"


function RoundModal(props) {
  const [visible, setVisible] = useState(0)
  const [confirmLoading, setConfirmLoading] = useState(0)
  const [createRoundMutation] = useMutation(CREATE_ROUND, {
    onCompleted({createRound: {id, name}}) {
      setVisible(false)
      setConfirmLoading(false)
    },
    refetchQueries: [{query: GET_QUIZ, variables: {"code": props.quiz.code}}],
  })
  const [updateRoundMutation] = useMutation(UPDATE_ROUND, {
    onCompleted() {
      setVisible(false)
      setConfirmLoading(false)
    },
  })
  const [deleteRoundMutation] = useMutation(DELETE_ROUND, {
    onCompleted({deleteRound: {id}}) {
      setVisible(false)
      setConfirmLoading(false)
    },
    refetchQueries: [{query: GET_QUIZ, variables: {"code": props.quiz.code}}],
  })


  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        setConfirmLoading(true)
        if (props.round) {
          updateRoundMutation({
            variables: {
              id: props.round.id,
              number: values.number,
              name: values.name,
              help_text: values.help_text,
            }
          })
        } else {
          createRoundMutation({
            variables: {
              number: values.number,
              name: values.name,
              help_text: values.help_text,
              quiz_id: props.quiz.id,
            }
          })
        }
      })
      .catch(info => {
        console.log("Validate Failed:", info)
      })
  }

  const deleteRound = (roundId) => {
    deleteRoundMutation({
      variables: {
        id: roundId,
      }
    })
    setVisible(false)
  }

  const [form] = Form.useForm()

  let initialValues = {}
  if (props.round) {
    initialValues = {
      "number": props.round.number,
      "name": props.round.name,
      "help_text": props.round.help_text,
    }
  } else {
    initialValues = {
      "number": props.nextRoundNumber,
      "name": "",
    }
  }

  let footerButtons = []
  if (props.round) {
    footerButtons.push(
      <Button key="delete" type="primary" onClick={() => deleteRound(props.round.id)} danger>
        Delete
      </Button>
    )
  }
  footerButtons = footerButtons.concat([
    <Button key="cancel" onClick={() => setVisible(false)}>
      Cancel
    </Button>,
    <Button key="submit" type="primary" onClick={handleOk}>
      {props.round ? "Save" : "Add round"}
    </Button>,
  ])

  return (
    <div>
      {props.round ? (
        <Space onClick={() => setVisible(true)}>
          <EditOutlined style={{fontSize: "24px",  "marginLeft": "5px", "marginRight": "0px"}} />
          <span>Edit round</span>
        </Space>
      ) : (
        <Space onClick={() => setVisible(true)} style={{cursor: "pointer", "color": "#1890ff"}}>
          <PlusCircleOutlined style={{fontSize: "24px"}} />
          <span>Add round</span>
        </Space>
      )}

      <Modal
        title={props.round ? "Edit round" : "Add round"}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        okText={props.round ? "Save" : "Add round"}
        cancelText="Cancel"
        destroyOnClose={true}
        footer={footerButtons}
      >
        <Form
          form={form}
          id="add-round"
          name="add-round"
          initialValues={initialValues}
          requiredMark="optional"
          layout="vertical"
          preserve={false}
        >
          <Form.Item
            label="Number"
            name="number"
            rules={[{required: true, message: "Please enter a number for the round"}]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[{required: true, message: "Please enter a name for the round"}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Help text"
            name="help_text"
            rules={[{required: false, message: "Help text (optional)"}]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}


export default RoundModal
