import React from "react"

import CreateQuizModal from "./CreateQuizModal"


function UpdateQuizModal(props) {
  return (
    <CreateQuizModal quiz={props.quiz} update={true} />
  )
}


export default UpdateQuizModal
