import React from "react"

import {useQuery} from "@apollo/react-hooks"
import {GET_CURRENT_USER} from "../graphql/queries"

export const UserContext = React.createContext()


export function UserContextProvider(props) {
  const {loading, error, data} = useQuery(GET_CURRENT_USER)

  let value = {
    user: null,
    userLoading: loading,
    userError: error,
  }

  if (data) value['user'] = data.getCurrentUser

  return (
    <UserContext.Provider value={value}>
        {props.children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
