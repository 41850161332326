import React, {useCallback, useContext} from "react"
import PropTypes from "prop-types"
import {useMutation} from "@apollo/react-hooks"
import {Image, Input} from "antd"
import {debounce} from "lodash"

import {QuestionPoints} from "./QuestionPoints"
import {QuizContext} from "../Quizzes"
import {ANSWER_QUESTION} from "../../graphql/mutations"

import "./Question.scss"


export function Question({question, roundCompleted, pointsReleased}) {
  const [answerQuestion] = useMutation(ANSWER_QUESTION)
  const {getMyTeamQuestion, myTeam, quizId, userCanEdit, userHasTeam} = useContext(QuizContext)

  const teamQuestion = userHasTeam ? getMyTeamQuestion(question.id) : null

  // Saves via API, but only once a second at most
  const bounceDelay = 1000
  const debouncedSave = useCallback(
    debounce(
      value => {
        answerQuestion({
          variables: {
            team_id: myTeam.id,
            question_id: question.id,
            answer: value,
            quiz_id: quizId
          }
        })
      },
      bounceDelay
    ),
    [],
  )

  const handleAnswerChange = (event) => {
    const value = event.target.value
    debouncedSave(value)
  }

  const awaitingPoints = roundCompleted && !pointsReleased

  return (
    <div className="question">
      <span className="question__number">
        <strong>{question.number}</strong>
      </span>

      <div className="question__content">
        <div className="question__question">
          {question.released_date && (
            question.question
          )}
        </div>

        {question.released_date && question.picture  && (
          <div className="question__picture">
            <Image
              src={process.env.REACT_APP_MEDIA_URL + question.picture}
              className="question__image"
            />
          </div>
        )}

        {question.released_date && question.audio && (
          <div className="question__audio">
            <audio controls controlsList="nodownload">
              <source src={process.env.REACT_APP_MEDIA_URL + question.audio} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )}

        {userHasTeam && (roundCompleted || (!userCanEdit && question.released_date)) && (
          <p className={"question__teamanswer" + (awaitingPoints ? " question__teamanswer--completed" : "")}>
            {teamQuestion && teamQuestion.answer}
          </p>
        )}

        {!roundCompleted && question.released_date && userCanEdit && (
          <Input
            style={question.picture ? {"marginTop": "16px"} : {}}
            className="question__input"
            id={"id_" + question.id}
            defaultValue={teamQuestion && teamQuestion.answer}
            autoComplete="off"
            onChange={handleAnswerChange}
          />
        )}

        {userHasTeam && pointsReleased && (
          <p className="question__answer">
            Answer: <strong>{question.answer}</strong>
          </p>
        )}
      </div>

      {teamQuestion && pointsReleased && (
        <QuestionPoints
          awardedPoints={teamQuestion.points}
          availablePoints={question.points}
        />
      )}
    </div>
  )
}

Question.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string,
    answer: PropTypes.string,
    audio: PropTypes.string,
    number: PropTypes.number,
    picture: PropTypes.string,
    points: PropTypes.number,
    question: PropTypes.string,
    released_date: PropTypes.string,
  }),
  roundCompleted: PropTypes.bool,
  pointsReleased: PropTypes.bool,
}

export default Question
