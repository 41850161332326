import React from "react"

import {useLazyQuery, useMutation, useSubscription} from "@apollo/react-hooks"

import {CONFIRM_TEAM_MEMBER, REJECT_TEAM_MEMBER} from "../../graphql/mutations"
import {
  ASKED_TO_JOIN_TEAM_SUBSCRIPTION,
  TEAM_MEMBER_CONFIRMED_SUBSCRIPTION,
  TEAM_MEMBER_REJECTED_SUBSCRIPTION
} from "../../graphql/subscriptions"
import {GET_TEAM} from "../../graphql/queries"

import {Button, List} from "antd"
import "./TeamMemberList.scss"


export function TeamMemberList(props) {
  const [getTeam] = useLazyQuery(GET_TEAM, {fetchPolicy: "network-only"})
  const [confirmTeamMember] = useMutation(CONFIRM_TEAM_MEMBER)
  const [rejectTeamMember] = useMutation(REJECT_TEAM_MEMBER, {
    refetchQueries: [{query: GET_TEAM, variables: {id: props.team.id}}],
  })

  useSubscription(ASKED_TO_JOIN_TEAM_SUBSCRIPTION, {
    variables: {
      team_id: props.team.id,
    },
    onSubscriptionData(options) {
      getTeam({variables: {id: props.team.id}})
    }
  })

  useSubscription(TEAM_MEMBER_CONFIRMED_SUBSCRIPTION, {
    skip: props.canEditTeam,
    variables: {
      team_id: props.team.id,
    },
    onSubscriptionData(options) {
      getTeam({variables: {id: props.team.id}})
    }
  })

  useSubscription(TEAM_MEMBER_REJECTED_SUBSCRIPTION, {
    skip: props.canEditTeam,
    variables: {
      team_id: props.team.id,
    },
    onSubscriptionData(options) {
      getTeam({variables: {id: props.team.id}})
    }
  })

  const handleConfirmTeamMember = (teamId, userId) => {
    confirmTeamMember({
      variables: {
        team_id: teamId,
        user_id: userId,
      }
    })
  }

  const handleRejectTeamMember = (teamId, userId) => {
    rejectTeamMember({
      variables: {
        team_id: teamId,
        user_id: userId,
      }
    })
  }

  return (
    <List
      className="team-member-list"
      size="small"
      dataSource={props.team.team_members}
      renderItem={teamMember => {
        let buttons = []
        if (props.canEditTeam && !teamMember.can_edit) {
          if (teamMember.confirmed) {
            buttons.push(<Button key="remove" size="small" onClick={() => handleRejectTeamMember(props.team.id, teamMember.user_id)}>Remove</Button>)
          } else {
            buttons.push(<Button key="accept" size="small" type="primary" onClick={() => handleConfirmTeamMember(props.team.id, teamMember.user_id)}>Accept</Button>)
            buttons.push(<Button key="reject" size="small" onClick={() => handleRejectTeamMember(props.team.id, teamMember.user_id)}>Reject</Button>)
          }
        }
        let memberName = teamMember.name || "Unknown"
        if (teamMember.can_edit) memberName += " (c)"
        if (!teamMember.confirmed) memberName += " (pending)"
        return (
          <li className="team-member-list-item" key={teamMember.id}>
            <span className="team-member-list-item__name">{memberName}</span>
            {buttons}
          </li>
        )
      }}
    />
  )
}


export default TeamMemberList
