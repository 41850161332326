import React, {useState} from "react"
import {useApolloClient} from "@apollo/react-hooks"
import {Form, message, Space, Upload} from "antd"
import {DeleteOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons"

import {GET_PRESIGNED_URL} from "../../graphql/queries"

import Resizer from "react-image-file-resizer"


function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!")
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!")
  }
  return isJpgOrPng && isLt2M
}


const resizeFile = (file, maxWidth, maxHeight) => new Promise(resolve => {
  Resizer.imageFileResizer(
    file, maxWidth, maxHeight, 'JPEG', 100, 0,
    uri => {
      resolve(uri)
    },
    'blob'
  )
})


export function ImageUploader(props) {
  const client = useApolloClient()
  const [loading, setLoading] = useState(false)
  const imageUrl = props.imagePath ? process.env.REACT_APP_MEDIA_URL + props.imagePath : null
  const setImagePath = props.setImagePath

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{marginTop: 8}}>Upload</div>
    </div>
  )

  const getPresignedUrl = async (filename) => {
    return await client.query({
      query: GET_PRESIGNED_URL,
      variables: {"path": props.upload_path, "filename": filename},
      fetchPolicy: "no-cache"
    })
  }

  const customRequest = async ({file, onSuccess}) => {
    getPresignedUrl(file.name).then(async (data) => {
      const presignedData = data.data.getPresignedUrl
      console.log("Uploading...", presignedData)
      const fields = JSON.parse(presignedData.fields)
      const formData = new FormData()

      Object.entries(fields).forEach(([k, v]) => {
        formData.append(k, v)
      })

      const image = await resizeFile(file, 300, 200)
      formData.append("file", image)

      await fetch(presignedData.url, {
        method: "POST",
        body: formData,
      }).then(response => {
        const path = fields.key.replace(/^(media\/)/,"")
        setImagePath(path)
      })
    })

    setTimeout(() => {
      onSuccess("ok")
    }, 0)
  }

  const normFile = e => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const handlePictureChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true)
    } else if (info.file.status === "done") {
      setLoading(false)
    }
  }

  return (
    <Form.Item
      label={props.label}
      valuePropName="fileList"
      getValueFromEvent={normFile}
    >

      <Upload
        name={props.name}
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={customRequest}
        beforeUpload={beforeUpload}
        onChange={handlePictureChange}
        multiple={false}
        style={{marginBottom: 0}}
      >
        {imageUrl ? (
          <img src={imageUrl} alt="" style={{width: "100%"}} />
        ) : uploadButton}
      </Upload>

      {imageUrl ? (
        <Space size={5} align="center" onClick={() => setImagePath(null)} style={{cursor: "pointer", "color": "#1890ff"}}>
          <DeleteOutlined style={{display: "block", marginRight: "5px", fontSize: "16px"}} />
          Clear
        </Space>
      ) : null}
    </Form.Item>
  )
}


export default ImageUploader
