import {gql} from "apollo-boost"


export const GET_QUIZ = gql`
  query getQuiz($code: String!) {
    getQuiz(code: $code) {
      id
      code
      name
      date
      cover_image
      public
      rounds {
        id
        number
        name
        help_text
        completed_date
        points_released_date
        questions {
          id
          number
          question
          answer
          picture
          audio
          points
          released_date
        }
      }
      created_by {
        id
        given_name
      }
    }
  }
`

export const GET_QUIZ_LIST = gql`
  query getQuizList($is_creator: Boolean) {
    getQuizList(is_creator: $is_creator) {
      id
      code
      name
      subtitle
      cover_image
      date
      is_creator
      created_by {
        id
        given_name
        username
      }
    }
  }
`

export const GET_ROUND = gql`
  query getRound($id: String!) {
    getRound(id: $id) {
      id
      number
      name
      completed_date
      points_released_date
      questions {
        id
        number
        question
        answer
        picture
        audio
        points
        released_date
        team_question {
          id
          answer
          points
        }
      }
    }
  }
`

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      name
      is_anonymous
    }
  }
`

export const GET_TEAM = gql`
  query getTeam($id: String!) {
    getTeam(id: $id) {
      id
      name
      team_members {
        id
        team_id
        user_id
        name
        can_edit
        confirmed
      }
    }
  }
`

export const GET_TEAMS = gql`
  query getTeams($quiz_id: String!) {
    getTeams(quiz_id: $quiz_id) {
      id
      name
      captain_name
    }
  }
`

export const GET_TEAMS_WITH_SCORES = gql`
  query getTeams($quiz_code: String!) {
    getTeams(quiz_code: $quiz_code) {
      id
      name
      captain_name
      team_members {
        id
        user_id
        name
        can_edit
        confirmed
      }
      team_rounds {
        id
        round {
          id
          points_released_date
        }
        team_questions {
          id
          answer
          points
        }
      }
    }
  }
`

export const GET_PRESIGNED_URL = gql`
  query getPresignedUrl($path: String!, $filename: String!) {
    getPresignedUrl(path: $path, filename: $filename) {
      url
      fields
    }
  }
`

export const GET_TEAM_QUESTION = gql`
  query getTeamQuestion($team_id: String!, $question_id: String!) {
    getTeamQuestion(team_id: $team_id, question_id: $question_id) {
      id
      answer
      points
    }
  }
`
