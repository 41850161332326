import React, {useContext, useState} from "react"
import PropTypes from "prop-types"

import {get} from "lodash"
import {useLazyQuery, useSubscription} from "@apollo/react-hooks"
import {Tabs} from "antd"

import {GET_QUIZ, GET_ROUND, GET_TEAMS_WITH_SCORES} from "../../graphql/queries"
import {
  POINTS_RELEASED_SUBSCRIPTION,
  QUESTION_ANSWERED_SUBSCRIPTION,
  QUESTION_RELEASED_SUBSCRIPTION,
  QUESTION_UNRELEASED_SUBSCRIPTION,
  ROUND_RELEASED_SUBSCRIPTION,
  ROUND_ENDED_SUBSCRIPTION,
  ROUND_REOPENED_SUBSCRIPTION,
} from "../../graphql/subscriptions"

import {RoundPanel} from "../Rounds"
import {TeamControls, Leaderboard} from "../Teams"
import {QuizContext} from "../Quizzes"

import "./Quiz.scss"


const {TabPane} = Tabs


export function Quiz(props) {
  const quiz = props.quiz
  const {myTeam, userHasTeam, userCanEdit} = useContext(QuizContext)
  const [roundScoresLoading, setRoundScoresLoading] = useState([])
  const [roundAnswersLoading, setRoundAnswersLoading] = useState([])
  const myTeamId = get(myTeam, "id", null)

  const [getQuiz] = useLazyQuery(GET_QUIZ, {fetchPolicy: "network-only"})
  const [getTeamsWithScores] = useLazyQuery(
    GET_TEAMS_WITH_SCORES,
    {
      fetchPolicy: "network-only",
      onCompleted() {
        setRoundScoresLoading([])
      }
    }
  )
  const [getRound] = useLazyQuery(
    GET_ROUND,
    {
      fetchPolicy: "network-only",
      onCompleted(data) {
        const newRoundAnswersLoading = roundAnswersLoading.filter((roundId) => roundId !== data.getRound.id)
        setRoundAnswersLoading(newRoundAnswersLoading)
      }
    }
  )

  useSubscription(QUESTION_RELEASED_SUBSCRIPTION, {variables: {quiz_id: quiz.id}})

  useSubscription(QUESTION_UNRELEASED_SUBSCRIPTION, {variables: {quiz_id: quiz.id}})

  useSubscription(
    ROUND_RELEASED_SUBSCRIPTION, {
      variables: {quiz_id: quiz.id},
      onSubscriptionData() {
        getQuiz({variables: {code: quiz.code}})
      }
    }
  )

  useSubscription(QUESTION_ANSWERED_SUBSCRIPTION, {
    skip: !userHasTeam || userCanEdit,
    variables: {team_id: myTeamId}
  })

  useSubscription(ROUND_ENDED_SUBSCRIPTION, {variables: {quiz_id: quiz.id}})
  useSubscription(ROUND_REOPENED_SUBSCRIPTION, {variables: {quiz_id: quiz.id}})
  useSubscription(
    POINTS_RELEASED_SUBSCRIPTION,
    {
      variables: {"quiz_id": quiz.id},
      onSubscriptionData(options) {
        let roundId = options.subscriptionData.data.roundPointsReleased.id
        setRoundScoresLoading(roundScoresLoading => [...roundScoresLoading, roundId])
        setRoundAnswersLoading(roundAnswersLoading => [...roundAnswersLoading, roundId])
        getTeamsWithScores({variables: {quiz_code: quiz.code}})
        getRound({variables: {"id": roundId}})
      }
    }
  )

  return (
    <div className="quiz">
      <h1 className="quiz__heading">{quiz.name}</h1>

      <TeamControls />

      <Tabs defaultActiveKey="1" tabBarGutter="10px" type="card">
        <TabPane tab="Leaderboard" key="1">
          <Leaderboard rounds={quiz.rounds} />
        </TabPane>

        <TabPane tab="Questions" key="2">
          <RoundPanel rounds={quiz.rounds} roundScoresLoading={roundScoresLoading} roundAnswersLoading={roundAnswersLoading} />
        </TabPane>
      </Tabs>
    </div>
  )
}

Quiz.propTypes = {
  quiz: PropTypes.object,
}

export default Quiz
