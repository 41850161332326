import React, {useState} from "react"
import {useMutation} from "@apollo/react-hooks"
import {Modal} from "antd"
import {Button, Checkbox, DatePicker, Form, Input, Space} from "antd"
import {EditOutlined} from "@ant-design/icons"
import ImageUploader from "../Upload/ImageUploader"
import {CREATE_QUIZ, UPDATE_QUIZ} from "../../graphql/mutations"
import moment from 'moment'


export function CreateQuizModal(props) {
  const [visible, setVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [imagePath, setImagePath] = useState(props.update ? props.quiz.cover_image : null)
  const [createQuizMutation] = useMutation(CREATE_QUIZ, {
    onCompleted({createQuiz: {code}}) {
      setVisible(false)
      setConfirmLoading(false)
      const redirectPath = `/quizzes/${code}/manage/`
      window.location = redirectPath
    },
  })
  const [updateQuizMutation] = useMutation(UPDATE_QUIZ, {
    onCompleted() {
      setVisible(false)
      setConfirmLoading(false)
    },
  })

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        setConfirmLoading(true)
        if (props.update) {
          updateQuizMutation({
            variables: {
              id: props.quiz.id,
              name: values.name,
              date: values.date ? values.date.toISOString() : null,
              cover_image: imagePath,
              public: values.public
            }
          })
        } else {
          createQuizMutation({
            variables: {
              name: values.name,
              date: values.date ? values.date.toISOString() : null,
              cover_image: imagePath,
              public: values.public
            }
          })
        }
      })
      .catch(info => {
        console.log("Validate Failed:", info)
      })
  }

  const [form] = Form.useForm()

  moment.updateLocale('en', {
    week: {dow: 1},
  })

  let initialValues = {}
  if (props.update) {
    initialValues = {
      "name": props.quiz.name,
      "date": props.quiz.date ? moment(props.quiz.date) : null,
      "public": props.quiz.public,
    }
  } else {
    initialValues = {"public": true}
  }

  return (
    <div>

      {props.update ? (
        <Space onClick={() => setVisible(true)}  style={{"marginBottom": "20px", "cursor": "pointer"}}>
          <EditOutlined style={{fontSize: "24px",  "marginLeft": "5px", "marginRight": "0px"}} />
          <span>Edit</span>
        </Space>
      ) :
        <Button type="primary" onClick={() => setVisible(true)} style={{"marginBottom": "16px"}}>
          Create Quiz
        </Button>
      }

      <Modal
        title={props.update ? "Update quiz" : "Create quiz"}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        okText={props.update ? "Update" : "Create quiz"}
        cancelText="Cancel"
        destroyOnClose={true}
      >
        <Form
          form={form}
          id="create-quiz"
          name="create-quiz"
          requiredMark={false}
          initialValues={initialValues}
          layout="vertical"
          preserve={false}
        >
          <Form.Item
            label="Quiz name"
            name="name"
            rules={[{required: true, message: "Please enter a name for the quiz"}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Quiz date (optional)"
            name="date"
            rules={[{required: false}]}
          >
            <DatePicker />
          </Form.Item>
          <ImageUploader
            label="Cover image (optional)"
            name="cover_image"
            upload_path="cover_images"
            imagePath={imagePath}
            setImagePath={setImagePath}
          />
          <Form.Item
            name="public"
            valuePropName="checked"
          >
            <Checkbox>Publicly visible (once the quiz has finished)</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}


export default CreateQuizModal
