import {gql} from "apollo-boost"


export const RELEASE_QUESTION = gql`
  mutation releaseQuestion($id: String!, $quiz_id: String!) {
    releaseQuestion(id: $id, quiz_id: $quiz_id) {
      id
      released_date
    }
  }
`


export const UNRELEASE_QUESTION = gql`
  mutation unreleaseQuestion($id: String!, $quiz_id: String!) {
    unreleaseQuestion(id: $id, quiz_id: $quiz_id) {
      id
      released_date
    }
  }
`

export const RELEASE_ROUND = gql`
  mutation releaseRoundQuestions($id: String!, $quiz_id: String!) {
    releaseRoundQuestions(id: $id, quiz_id: $quiz_id) {
      id
      released_date
    }
  }
`


export const UNRELEASE_ROUND = gql`
  mutation unreleaseRoundQuestions($id: String!, $quiz_id: String!) {
    unreleaseRoundQuestions(id: $id, quiz_id: $quiz_id) {
      id
      released_date
    }
  }
`


export const MARK_TEAM_QUESTION = gql`
  mutation markTeamQuestion($team_id: String!, $question_id: String!, $points: Float) {
    markTeamQuestion(team_id: $team_id, question_id: $question_id, points: $points) {
      id
      points
    }
  }
`


export const END_ROUND = gql`
  mutation endRound($id: String!, $quiz_id: String!) {
    endRound(id: $id, quiz_id: $quiz_id) {
      id
      completed_date
    }
  }
`


export const REOPEN_ROUND = gql`
  mutation reopenRound($id: String!, $quiz_id: String!) {
    reopenRound(id: $id, quiz_id: $quiz_id) {
      id
      completed_date
      points_released_date
    }
  }
`


export const RELEASE_ROUND_POINTS = gql`
  mutation releaseRoundPoints($id: String!, $quiz_id: String!) {
    releaseRoundPoints(id: $id, quiz_id: $quiz_id) {
      id
      points_released_date
    }
  }
`


export const UNRELEASE_ROUND_POINTS = gql`
  mutation unreleaseRoundPoints($id: String!, $quiz_id: String!) {
    unreleaseRoundPoints(id: $id, quiz_id: $quiz_id) {
      id
      points_released_date
    }
  }
`


export const CREATE_QUIZ = gql`
  mutation createQuiz($name: String!, $date: String, $cover_image: String, $public: Boolean) {
    createQuiz(name: $name, date: $date, cover_image: $cover_image, public: $public) {
      id
      name
      code
      date
      cover_image
      public
    }
  }
`


export const CREATE_QUESTION = gql`
  mutation createQuestion($round_id: String!, $number: Float, $question: String!, $picture: String, $audio: String, $answer: String!, $points: Float) {
    createQuestion(round_id: $round_id, number: $number, question: $question, picture: $picture, audio: $audio, answer: $answer, points: $points) {
      number
      question
      picture
      audio
      answer
      points
    }
  }
`


export const CREATE_ROUND = gql`
  mutation createRound($number: Float!, $name: String!, $help_text: String, $quiz_id: String!) {
    createRound(number: $number, name: $name, help_text: $help_text, quiz_id: $quiz_id) {
      id
      name
    }
  }
`


export const UPDATE_QUESTION = gql`
  mutation updateQuestion($id: String!, $number: Float, $question: String!, $picture: String, $audio: String, $answer: String!, $points: Float) {
    updateQuestion(id: $id, number: $number, question: $question, picture: $picture, audio: $audio, answer: $answer, points: $points) {
      id
      number
      question
      picture
      audio
      answer
      points
    }
  }
`


export const UPDATE_QUIZ = gql`
  mutation updateQuiz($id: String!, $name: String!, $date: String, $cover_image: String, $public: Boolean) {
    updateQuiz(id: $id, name: $name, date: $date, cover_image: $cover_image, public: $public) {
      id
      name
      date
      cover_image
      public
    }
  }
`


export const UPDATE_ROUND = gql`
  mutation updateRound($id: String!, $number: Float!, $name: String!, $help_text: String) {
    updateRound(id: $id, number: $number, name: $name, help_text: $help_text) {
      id
      number
      name
      help_text
    }
  }
`

export const DELETE_ROUND = gql`
  mutation deleteRound($id: String!) {
    deleteRound(id: $id) {
      id
    }
  }
`

export const DELETE_QUESTION = gql`
  mutation deleteQuestion($id: String!) {
    deleteQuestion(id: $id) {
      id
    }
  }
`

export const CREATE_TEAM = gql`
  mutation createTeam($name: String!, $captain_name: String!, $quiz_id: String!) {
    createTeam(name: $name, captain_name: $captain_name, quiz_id: $quiz_id) {
      id
      name
      captain_name
    }
  }
`

export const ANSWER_QUESTION = gql`
  mutation answerQuestion($team_id: String!, $question_id: String!, $answer: String!, $quiz_id: String!, $question_id: String!) {
    answerQuestion(team_id: $team_id, question_id: $question_id, answer: $answer, quiz_id: $quiz_id) {
      id
      answer
      team {
        id
      }
      question {
        id
      }
    }
  }
`

export const ASK_TO_JOIN_TEAM = gql`
  mutation askToJoinTeam($team_id: String!, $name: String!) {
    askToJoinTeam(team_id: $team_id, name: $name) {
      id
      team_id
      user_id
      name
      confirmed
    }
  }
`


export const CONFIRM_TEAM_MEMBER = gql`
  mutation confirmTeamMember($team_id: String!, $user_id: String!) {
    confirmTeamMember(team_id: $team_id, user_id: $user_id) {
      id
      team_id
      user_id
      name
      confirmed
    }
  }
`

export const REJECT_TEAM_MEMBER = gql`
  mutation rejectTeamMember($team_id: String!, $user_id: String!) {
    rejectTeamMember(team_id: $team_id, user_id: $user_id) {
      team_id
      user_id
    }
  }
`
