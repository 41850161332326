import React, {useState} from "react"
import PropTypes from "prop-types"

import {Round} from "./Round"
import {RoundSelector} from "./RoundSelector"

import "./RoundPanel.scss"


export function RoundPanel({rounds, roundScoresLoading, roundAnswersLoading}) {
  let [activeRoundIndex, setActiveRoundIndex] = useState(0)
  let activeRound = rounds[activeRoundIndex]

  return (
    <div className="round-panel">
      <RoundSelector
        rounds={rounds}
        activeRoundIndex={activeRoundIndex}
        handleSelectRound={(roundIndex) => setActiveRoundIndex(roundIndex)}
      />
      <Round
        round={activeRound}
        pointsLoading={roundScoresLoading.includes(activeRound.id)}
        answersLoading={roundAnswersLoading.includes(activeRound.id)}
      />
    </div>
  )
}


RoundPanel.propTypes = {
  rounds: PropTypes.arrayOf(PropTypes.object),
}

export default RoundPanel
