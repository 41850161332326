import React, {useContext, useState} from "react"

import {useMutation} from "@apollo/react-hooks"

import {Button, Table} from "antd"

import {CREATE_QUESTION} from "../../graphql/mutations"
import {GET_QUIZ} from "../../graphql/queries"
import {PointsInput} from "./inputs"
import Question from "./Question"
import QuestionForm from "./QuestionForm"
import {QuizContext} from "../Quizzes"


function RoundTable(props) {
  const [showAddQuestionForm, setShowAddQuestionForm] = useState(false)
  const {getTeamQuestion, quizCode} = useContext(QuizContext)
  const [createQuestion] = useMutation(CREATE_QUESTION, {
    refetchQueries: [{query: GET_QUIZ, variables: {"code": quizCode}}],
  })

  const handleAddQuestionSubmit = (roundId, values) => {
    createQuestion({
      variables: {
        round_id: roundId,
        number: values.number,
        question: values.question,
        picture: values.picture,
        audio: values.audio,
        answer: values.answer,
        points: values.points,
      }
    })
    setShowAddQuestionForm(false)
  }

  const getNextQuestionNumber = () => {
    const questionNumbers =  props.round.questions.reduce( (acc, item, index) => {
      acc.push(item.number)
      return acc
    }, [])

    for (let i = 1; i <= questionNumbers.length + 1; i++) {
      if (questionNumbers.indexOf(i) === -1) {
        return i
      }
    }
  }

  const getTeamQuestionWidth = (teamCount) => {
    let percentage = 70 / teamCount
    return percentage + "%"
  }

  let dataSource = props.round.questions.map(function(question, index) {
    let data = {
      key: index,
      question: question,
    }
    for (var i = 0; i < props.teams.length; i++) {
      data["team" + i] = getTeamQuestion(props.teams[i]["id"], question.id)
    }
    return data
  })

  if (showAddQuestionForm) {
    dataSource.push({
      "key": dataSource.length + 1,
      "question": (
        <QuestionForm
          handleSubmit={values => {
            handleAddQuestionSubmit(props.round.id, values)
          }}
          handleCancelClick={() => setShowAddQuestionForm(false)}
          nextQuestionNumber={getNextQuestionNumber()}
        />
      )
    })
  } else {
    dataSource.push({
      "key": dataSource.length + 1,
      "question": <Button onClick={() => setShowAddQuestionForm(true)}>Add question</Button>
    })
  }

  let columns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      width: "30%",
      render: object => {
        if (React.isValidElement(object)) {
          return object
        } else {
          return <Question question={object} />
        }
      }
    },
  ]

  columns = columns.concat(props.teams.map(function(team, index) {
    return {
      title: team.name,
      dataIndex: "team" + index,
      key: "team" + index,
      align: "center",
      width: getTeamQuestionWidth(props.teams.length),
      render: team_question => {
        if (!team_question) {
          return ""
        } else {
          return (
            <div>
              <span className="manage-question__answer">{team_question.answer}</span>
              <PointsInput team_question={team_question} />
            </div>
          )
        }
      }
    }
  }))

  return <Table dataSource={dataSource} columns={columns} pagination={{"hideOnSinglePage": true, "defaultPageSize": 21}} scroll={{x: 800}} sticky={{offsetHeader: 0}} />
}


export default RoundTable
