import React, {useContext} from "react"

import {useQuery} from "@apollo/react-hooks"
import {Card} from "antd"

import {UserContext} from "../../auth/user"
import {GET_QUIZ_LIST} from "../../graphql/queries"

import "./QuizList.css"

const {Meta} = Card


export function QuizList(props) {
  const {user} = useContext(UserContext)
  let variables = {}
  if (props.userQuizzes) variables = {"is_creator": true}
  const {loading, error, data} = useQuery(GET_QUIZ_LIST, {variables: variables})

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error :(</p>

  return (
    <div>
      {props.title !== null ? (
        <h2 className="quiz-list__title">{props.title}</h2>
      ) : (
        null
      )}

      {props.extra}

      <ul className="quiz-list">
        {
          data.getQuizList.map((quiz) => {
            let formattedDate = null
            if (quiz.date) {
              formattedDate = new Intl.DateTimeFormat("en-GB", {
                year: "numeric",
                month: "long",
                day: "numeric"
              }).format(Date.parse(quiz.date))
            }

            const description = (
              <div className="quiz-list__item-content">
                {quiz.subtitle ? <p>{quiz.subtitle}</p> : null}
                {formattedDate ? <p>{formattedDate}</p> : null}
                {quiz.created_by ? <p>by {quiz.created_by.given_name ? quiz.created_by.given_name : quiz.created_by.username}</p> : null}
              </div>
            )
            return (
              <li className="quiz-list__item" key={quiz.code}>
                <Card
                  hoverable
                  style={{width: 300, float: "left", margin: "0 10px 10px 0"}}
                  cover={
                    <img
                      alt="example"
                      src={quiz.cover_image ? (
                        process.env.REACT_APP_MEDIA_URL + quiz.cover_image
                      ) : (
                        process.env.REACT_APP_STATIC_URL + "images/default_cover_image.jpg"
                      )}
                    />
                  }
                  actions={quiz.created_by && user && quiz.created_by.id === user.id ? (
                    [<a key="manage" href={"/quizzes/" + quiz.code + "/manage/"}>Manage</a>]
                  ) : (
                    []
                  )}
                >
                  <Meta
                    title=<a href={"/quizzes/" + quiz.code + "/"}>{quiz.name}</a>
                    description={description}
                  />
                </Card>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}


export default QuizList
