import React from "react"

import {useMutation} from "@apollo/react-hooks"

import {
  RELEASE_QUESTION,
  UNRELEASE_QUESTION,
  END_ROUND,
  REOPEN_ROUND,
  RELEASE_ROUND_POINTS,
  UNRELEASE_ROUND_POINTS,
  RELEASE_ROUND,
  UNRELEASE_ROUND,
} from "../../graphql/mutations"
import {GET_TEAMS_WITH_SCORES} from "../../graphql/queries"

import {Button, Space} from "antd"


export function ReleaseButton(props) {
  const [releaseQuestion] = useMutation(RELEASE_QUESTION)

  return (
    <Button className="manage-question__release" type="primary" onClick={event => {
      releaseQuestion({variables: {id: props.question_id, quiz_id: props.quiz_id}})
    }}>Release</Button>
  )
}


export function UnreleaseButton(props) {
  const [unreleaseQuestion] = useMutation(UNRELEASE_QUESTION)

  return (
    <Button className="manage-question__release" onClick={event => {
      unreleaseQuestion({variables: {id: props.question_id, quiz_id: props.quiz_id}})
    }}>Unrelease</Button>
  )
}


export function ReleaseRoundButton(props) {
  const [releaseRound] = useMutation(RELEASE_ROUND)

  return (
    <Button onClick={event => {
      releaseRound({variables: {id: props.round_id, quiz_id: props.quiz_id}})
    }}>Release all questions</Button>
  )
}


export function UnreleaseRoundButton(props) {
  const [unreleaseRound] = useMutation(UNRELEASE_ROUND)

  return (
    <Button onClick={event => {
      unreleaseRound({variables: {id: props.round_id, quiz_id: props.quiz_id}})
    }}>Unrelease all questions</Button>
  )
}


export function EndRoundButton(props) {
  const [endRound] = useMutation(END_ROUND, {
    refetchQueries: [{query: GET_TEAMS_WITH_SCORES, variables: {"quiz_code": props.quiz_code}}],
  })

  return (
    <Button className="manage-question__end-round" type="primary" onClick={event => {
      endRound({variables: {id: props.round_id, quiz_id: props.quiz_id}})
    }}>End Round</Button>
  )
}


export function ReopenRoundButton(props) {
  const [reopenRound] = useMutation(REOPEN_ROUND)

  return (
    <Button className="manage-question__reopen-round" onClick={event => {
      reopenRound({variables: {id: props.round_id, quiz_id: props.quiz_id}})
    }}>Reopen Round</Button>
  )
}


export function ReleasePointsButton(props) {
  const [releaseRoundPoints] = useMutation(RELEASE_ROUND_POINTS)

  return (
    <Button className="manage-question__release-points" type="primary" onClick={event => {
      releaseRoundPoints({variables: {id: props.round_id, quiz_id: props.quiz_id}})
    }}>Release Points</Button>
  )
}


export function UnreleasePointsButton(props) {
  const [unreleaseRoundPoints] = useMutation(UNRELEASE_ROUND_POINTS)

  return (
    <Button onClick={event => {
      unreleaseRoundPoints({variables: {id: props.round_id, quiz_id: props.quiz_id}})
    }}>Unrelease Points</Button>
  )
}


export function RoundActions(props) {
  const allQuestionsReleased = props.round.questions.every(question => question.released_date != null)
  const noQuestionsReleased = props.round.questions.every(question => question.released_date == null)

  if (props.round.completed_date == null) {
    return (
      <Space>
        {!noQuestionsReleased ? (
          <EndRoundButton round_id={props.round.id} quiz_id={props.quiz.id} quiz_code={props.quiz.code}  />
        ) : (
          null
        )}

        {noQuestionsReleased || !allQuestionsReleased ? (
          <ReleaseRoundButton round_id={props.round.id} quiz_id={props.quiz.id} />
        ) : (
          null
        )}

        {allQuestionsReleased || !noQuestionsReleased ? (
          <UnreleaseRoundButton round_id={props.round.id} quiz_id={props.quiz.id} />
        ) : (
          null
        )}
      </Space>
    )
  }
  return (
    <Space>
      {props.round.points_released_date == null ? (
        <ReleasePointsButton round_id={props.round.id} quiz_id={props.quiz.id} />
      ) : (
        <UnreleasePointsButton round_id={props.round.id} quiz_id={props.quiz.id} />
      )}
      <ReopenRoundButton round_id={props.round.id} quiz_id={props.quiz.id} />
    </Space>
  )
}
